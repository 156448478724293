import(/* webpackMode: "eager", webpackExports: ["ContestStatCard"] */ "/home/runner/work/oii-stats/oii-stats/frontend/app/(home)/contest.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageClient"] */ "/home/runner/work/oii-stats/oii-stats/frontend/app/(home)/page-client.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/frontend/node_modules/@olinfo/react-components/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/ABR.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/BAS.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/CAL.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/CAM.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/EMI.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/FRI.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/LAZ.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/LIG.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/LOM.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/MAR.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/MOL.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/PIE.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/PUG.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/SAR.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/SIC.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/TOS.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/TRE.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/UMB.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/VAL.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/oii-stats/oii-stats/static/regions/VEN.svg");
